.overflow-menu {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
}

.overflow-menu--top-right {
  position: absolute;
  top: 0;
  right: 0;

  @include respond-to(phones) {
    top: 0; // align button with the top edge even when margin is non existing
    right: 14px; // align button with the right edge even when margin is non existing
  }
}
