.back-to-top-button {
  position: fixed;
  right: $back-to-top-button-right-position;
  bottom: $back-to-top-button-bottom-position;
  z-index: map-get($zindex, bump);
  opacity: 0;
  transition: opacity $default-animation-speed;

  button {
    @include shadow(elevated);
    background-color: palette(greyscale-level-7);
    border: 0;
    border-radius: radius(round);
  }
}

.back-to-top-button--visible {
  opacity: 1;
}
