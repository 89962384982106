// scss-lint:disable ImportantRule

.right,
.u-float-right {
  float: right !important;

  &\@phones {
    @include respond-to(phones) {
      float: right !important;
    }
  }
}

.left,
.u-float-left {
  float: left !important;

  &\@phones {
    @include respond-to(phones) {
      float: right !important;
    }
  }
}

.u-float-none {
  float: none !important;
}

.u-transform-style-preserve-3d {
  transform-style: preserve-3d;
}

// Positioning

.rel,
.u-position-relative {
  position: relative !important;

  &\@phones {
    @include respond-to(phones) {
      position: relative !important;
    }
  }

  &\@tablets-up {
    @include respond-to(tablets-up) {
      position: relative !important;
    }
  }
}

.abs,
.u-position-absolute {
  position: absolute;
}

.u-position-initial {
  position: initial;
}

.u-zindex-bump {
  z-index: map-get($zindex, bump);
}

.u-zindex-small {
  z-index: map-get($zindex, small);
}

.u-zindex-large {
  z-index: map-get($zindex, large);
}

// Display

.block,
.u-block {
  display: block !important;

  &\@phones {
    @include respond-to(phones) {
      display: block !important;
      width: 100% !important;
    }
  }
}

.inline-block,
.u-inline-block {
  display: inline-block !important;
}

.u-inline {
  display: inline !important;
}

.u-table {
  display: table !important;
}

.u-table-cell {
  display: table-cell !important;
}

.u-flexbox {
  display: flex;
}

.u-inline-flex {
  display: inline-flex !important;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.u-border-box {
  box-sizing: border-box;
}

// Sizing

.u-fill-height {
  height: 100% !important;
}

.u-fill-width {
  width: 100% !important;
}

.u-fill-child-component > * {
  @include size(100%);
}

.u-half-width {
  width: 50% !important;
}

.u-fill-viewport {
  min-height: 100vh !important;
}

.u-fill-dynamic-viewport {
  min-height: 100vh !important;
  min-height: 100dvh !important;
}

.u-flat {
  &\@portables {
    @include respond-to(portables) {
      width: 100% !important;
    }
  }

  &\@phones {
    @include respond-to(phones) {
      width: 100% !important;
    }
  }
}

.u-nowrap {
  white-space: nowrap;

  &\@tablets {
    @include respond-to(tablets) {
      white-space: nowrap;
    }
  }
}

.u-force-wrap {
  @include force-wrap;
}

.u-ellipsis {
  @include ellipsis;
}

.u-stretch-height {
  right: 0;
  left: 0;
  width: auto !important;
  height: 100% !important;
  margin: auto;
}

.u-stretch-width {
  top: 0;
  bottom: 0;
  width: 100% !important;
  height: auto !important;
  margin: auto;
}

.u-margin-auto {
  margin: auto;
}

.u-min-width-none {
  min-width: 0;
}

.u-min-height-none {
  min-height: 0;
}

// Alignment

.u-vertical-top {
  vertical-align: top;
}

.u-vertical-baseline {
  vertical-align: baseline;
}

.u-vertical-middle {
  vertical-align: middle;
}

.u-vertical-text-top {
  vertical-align: text-top;
}

.u-vertical-text-bottom {
  vertical-align: text-bottom;
}

// Text

.u-text-center {
  text-align: center !important;

  &\@phones {
    @include respond-to(phones) {
      text-align: center;
    }
  }
}

.u-text-right {
  text-align: right !important;
}

.u-text-left {
  text-align: left !important;
}

.u-text-wrap {
  word-break: normal;
  @include word-wrap;
}

.u-line-height-none {
  line-height: 0;
}

// Styling

.circle,
.u-rounded-fully {
  @include fully-rounded;
}

.u-rounded {
  border-radius: $box-border-radius;
}

.u-background-white {
  background: $color-background-white;
}

.u-background-theme {
  background: palette(primary-default) !important;
}

.u-background-gray {
  background: $color-background-light;
}

.u-background-greyscale-level-1 {
  background: palette(greyscale-level-1) !important;
}

.u-background-greyscale-level-6 {
  background: palette(greyscale-level-6);
}

.u-background-greyscale-level-7 {
  background: palette(greyscale-level-7) !important;
}

.u-underlined {
  text-decoration: underline;
}

.u-disable-hover {
  &:hover {
    text-decoration: none;
  }
}

.u-disable-underline-without-hover {
  text-decoration: none;
}

.u-disable-underline {
  @extend .u-disable-hover;

  text-decoration: none;
}

.u-disabled {
  pointer-events: none;
  opacity: 0.48;
}

.u-color-inherit {
  color: inherit;
}

.u-color-greyscale-level-1 {
  color: palette(greyscale-level-1);
}

// States (Shouldn’t be styled directly!!!)

.hidden,
[hidden],
.is-hidden {
  display: none !important;
}

.u-hidden {
  display: none;

  &\@portables {
    @include respond-to(portables) {
      display: none;
    }
  }

  &\@desktops {
    @include respond-to(desktops) {
      display: none;
    }
  }

  &\@phones {
    @include respond-to(phones) {
      display: none;
    }
  }

  &\@webview {
    .is-webview & {
      display: none;
    }
  }
}

// Used for cases, when you need to hide something, but preserve
// container's dimensions etc. (some scrips may require fixed parent height, e.g. maps container)
.is-invisible {
  @include invisible;
}

.is-visually-hidden {
  z-index: map-get($zindex, below); // make sure element is unclickable
  opacity: 0;
}

.u-visibility-hidden {
  visibility: hidden;
}

.u-cursor-default {
  cursor: default;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-cursor-grab {
  cursor: grab;
}

.u-cursor-grabbing {
  cursor: grabbing;
}

.u-user-select-none {
  @include user-select(none);
}

.is-scroll-locked {
  overflow: hidden;
}

.is-floating {
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get($zindex, above-overlay);
  width: 100%;
  height: 100%;
  overflow: auto;
}

.u-code-input {
  font-family: "Source Code Pro", monospace;
  resize: vertical !important;
}

.u-align-content-flex-end {
  align-content: flex-end;
}

.u-align-self-baseline {
  align-self: baseline;
}

.u-align-self-center {
  align-self: center;
}

.u-align-self-flex-end {
  align-self: flex-end;
}

.u-align-self-flex-start {
  align-self: flex-start;
}

.u-flex-grow {
  flex-grow: 1;
}

.u-flex-equal-size {
  flex-grow: 1;
  flex-basis: 0;
}

.u-align-items-center {
  align-items: center;
}

.u-align-items-baseline {
  align-items: baseline;
}

.u-align-items-flex-end {
  align-items: flex-end;
}

.u-align-items-flex-start {
  align-items: flex-start;
}

.u-width-auto {
  width: auto !important;
}

.u-width-min-content {
  width: min-content;
}

.u-with-small-right-arrow {
  @include right-arrow(small);
}

.u-justify-content-between {
  justify-content: space-between;
}

.u-justify-content-center {
  justify-content: center;
}

.u-justify-content-flex-start {
  justify-content: flex-start;
}

.u-justify-content-flex-end {
  justify-content: flex-end;
}

.u-flex-direction-column {
  flex-direction: column;
}

.u-flex-direction-column-reverse {
  flex-direction: column-reverse;
}

.u-box-shadow {
  box-shadow: $box-shadow;
}

.u-clearfix {
  @include clearfix;
}

.u-no-pointer-events {
  pointer-events: none;
}

.u-auto-pointer-events {
  pointer-events: auto;
}

.u-overflow-hidden {
  overflow: hidden;
  border-radius: inherit;
}

.u-border-radius-inherit {
  border-radius: inherit;
}

.u-overflow-visible {
  overflow: visible !important;
}

.u-overflow-auto {
  overflow: auto !important;
}

.u-inner-outline {
  * {
    outline-offset: -10px;
  }
}

.u-invisible-overlay {
  @include content;
  @include stretch;
  cursor: pointer;
}

.u-z-index-notification {
  z-index: map-get($zindex, notification);
}

.u-sticky {
  position: sticky;
}

.u-sticky-bottom {
  position: fixed;
  bottom: 0;
}

.u-right {
  right: 0;
}

.u-bottom {
  bottom: 0;
}

.u-top {
  top: 0;
}

.u-fixed-top {
  position: fixed;
  top: 0;
}

.u-sticky-top {
  @include sticky;
  top: 0;
}

.u-visually-hidden {
  @include visually-hidden();
}

.u-fit-content-width {
  width: fit-content;
}

.u-flex-shrink-none {
  flex-shrink: 0;
}

.u-grid {
  display: grid;
}

.u-contents {
  display: contents;
}

.u-overflow-y-unset {
  overflow-y: unset !important;
}

.u-flex-none {
  flex: none;
}

.u-flex-auto {
  flex: auto
}

.u-white-space-normal {
  white-space: normal;
}

.u-flex-1 {
  flex: 1;
}
