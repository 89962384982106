.bump-items_content-overlay {
  position: relative;

  .button-overlay {
    @include stretch(0,0, null, null);
    @include fully-rounded;
    z-index: map-get($zindex, overlay);
    background-color: palette(greyscale-level-7);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    margin: 8px;
  }

  .button-overlay--dark {
      background-color: palette(greyscale-level-1);
  }
}

