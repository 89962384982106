/**
 * TODO:
 * adjust the styling with the new design
 * Use v2 prefix ==> e.g. influencer-v2-description
 * */

@mixin influencer-max-width() {
    margin: 0 auto;
    width: 100%;
    max-width: unit(263.5);
  }
  
  @mixin influencer-title() {
    color: palette(greyscale-level-1);
    font-weight: 500;
    font-size: unit(8);
    line-height: unit(10.5);
    text-align: center;
  
    @include respond-to(desktops) {
      font-size: unit(10.5);
      line-height: unit(13);
    }
  }
  
  @mixin influencer-description {
    color: palette(greyscale-level-1);
    margin: 0 auto;
    font-weight: 500;
    font-size: unit(4);
    line-height: unit(5.5);
    text-align: center;
  
    @include respond-to(desktops) {
      max-width: unit(211);
      font-size: unit(6);
      line-height: unit(8);
    }
  }
  
  @mixin influencer-card-title {
    color: palette(greyscale-level-1);
    font-weight: 500;
    font-size: unit(4);
    line-height: unit(5.5);
    margin: 0;
    padding: unit(4) 0 unit(3) 0;
  
    @include respond-to(desktops) {
      padding: unit(8) 0 unit(3) 0;
    }
  }
  
  @mixin influencer-card-description {
    color: palette(greyscale-level-2);
    font-weight: 375;
    font-size: unit(4);
    line-height: unit(5.5);
    margin: 0;
  }
  
  .influencer-landing-page {
    position: relative;
    display: block;
  }
  
  .influencer_introduction-section {
    @include influencer-max-width;
  }
  
  .influencer__introduction-title {
    @include influencer-title;
    margin: 0 auto;
    max-width: unit(83.75);
    padding-top: unit(5);
  
    @include respond-to(desktops) {
      padding-top: unit(11);
      max-width: none;
    }
  }
  
  .influencer__introduction-description {
    @include influencer-description;
  
    font-weight: 375;
    padding-top: unit(8);
    padding-bottom: unit(8);
  
    @include respond-to(desktops) {
      padding-top: unit(12);
      padding-bottom: unit(12);
    }
  }
  
  .influencer__introduction-cta {
    margin: 0 auto;
    width: 100%;
    margin-bottom: unit(12);
  
    @include respond-to(desktops) {
      width: fit-content;
      margin-bottom: unit(16);
    }
  }
  
  .influencer__introduction-image {
    width: 100vw;
    height: unit(75);
    position: relative;
    left: calc(-50vw + 50%);
  
    @include respond-to(desktops) {
      height: unit(175);
    }
  }
  
  .influencer__tips-section {
    width: 100vw;
    position: relative;
    overflow-x: hidden;
    left: calc(-50vw + 50%);
  
    @include respond-to(desktops) {
      @include influencer-max-width;
  
      left: 0;
    }
  }
  
  .influencer__tips-title {
    @include influencer-title;
  
    text-align: left;
    padding-top: unit(12);
    padding-bottom: unit(4);
    margin-left: unit(2.5);
  
    @include respond-to(desktops) {
      max-width: unit(175);
      padding-top: unit(16);
      padding-bottom: unit(16);
      margin-left: 0;
    }
  }
  
  .influencer__tips-cards-wrapper {
    display: block;
    width: 100%;
    margin-bottom: unit(10.5);
    padding-left: unit(2.5);
    gap: unit(5);
  
    @include respond-to(desktops) {
      gap: unit(8);
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      margin-bottom: unit(16);
      padding-left: 0;
    }
  }
  
  .influencer__tips-card {
    width: unit(66);
  
    @include respond-to(desktops) {
      flex: 1 1 unit(82.5);
      min-width: unit(66);
      width: 100%;
    }
  }
  
  .influencer__tips-card-image {
    height: unit(80);
    width: unit(66);
  
    @include respond-to(desktops) {
      width: unit(82.5);
      height: unit(100);
    }
  }
  
  .influencer__tips-card-title {
    @include influencer-card-title();
  }
  
  .influencer__tips-card-description {
    @include influencer-card-description();
  }
  
  .influencer__lifestyle-section {
    @include respond-to(desktops) {
      overflow: hidden;
      width: 100vw;
      position: relative;
      left: calc(-50vw + 50%);
    }
  }
  
  .influencer__lifestyle-section-inner {
    @include influencer-max-width;
  
    @include respond-to(desktops) {
      padding-left: unit(5);
    }
  }
  
  .influencer__lifestyle-title {
    @include influencer-title;
  
    text-align: left;
    padding-top: unit(12);
    padding-bottom: unit(12);
  
    @include respond-to(desktops) {
      max-width: unit(175);
      padding-top: unit(16);
      padding-bottom: unit(4);
    }
  }
  
  .influencer__lifestyle-cards-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: unit(8);
    margin-bottom: unit(12);
    overflow-x: hidden;
  
    @include respond-to(desktops) {
      overflow-x: visible;
      display: block;
      gap: unit(8);
    }
  }
  
  .influencer__lifestyle-card {
    width: 100%;
    height: fit-content;
  
    @include respond-to(tablets-up) {
      width: unit(130);
    }
  }
  
  .influencer__lifestyle-card-image {
    width: 100%;
    height: 100%;
  
    @include respond-to(tablets-up) {
      width: unit(130);
      height: unit(160);
    }
  }
  
  .influencer__lifestyle-card-title {
    @include influencer-card-title();
  }
  
  .influencer__lifestyle-card-description {
    @include influencer-card-description();
  
    max-width: unit(93.25);
  }
  
  .influencer__closet-title {
    @include influencer-title;
  
    text-align: center;
    padding-top: unit(12);
    padding-bottom: unit(8);
  
    @include respond-to(desktops) {
      padding-top: unit(16);
    }
  }
  
  .influencer__closet-section {
    background-color: palette(greyscale-level-6);
    display: block;
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    overflow-x: hidden;
  }
  
  .influencer__closet-section-inner {
    display: block;
    text-decoration: none;
  
    &:hover {
      text-decoration: none;
    }
  }
  
  .influencer__closet-cards-wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: unit(311);
    display: block;
    overflow: hidden;
    gap: unit(2);
    margin-bottom: unit(0);
    padding-left: 2.5vw;
  
    @include respond-to(wide) {
      display: flex;
      flex-direction: row;
      justify-content: left;
      margin-bottom: unit(0);
      gap: unit(4);
      padding-left: 0;
    }
  }
  
  .influencer__closet-card {
    height: fit-content;
  }
  
  .influencer__closet-card-image {
    width: unit(35);
    height: unit(50.75);
  
    @include respond-to(tablets-up) {
      width: unit(48.5);
      height: unit(64);
    }
  }
  
  .influencer__closet-card-title {
    color: palette(greyscale-level-3);
    font-weight: 375;
    font-size: unit(3);
    line-height: unit(4);
    margin: 0;
    padding: unit(2);
    display: flex;
    justify-content: space-between;
    text-decoration: none;
  }
  
  .influencer__closet-cta-wrapper {
    width: 95vw;
    margin: 0 auto;
  }
  
  .influencer__closet-cta {
    margin: 0 auto;
    width: 100%;
    padding-top: unit(8);
    padding-bottom: unit(12);
  
    @include respond-to(desktops) {
      width: fit-content;
      padding-bottom: unit(16);
    }
  }
  
  .influencer__product-card {
    height: fit-content;
    width: 100%;
  
    a {
      text-decoration: none;
    }
  
    @include respond-to(wide) {
      max-width: unit(48.5);
    }
  }
  
  .influencer__product-card-image {
    width: unit(35);
    height: unit(50.75);
  
    @include respond-to(wide) {
      width: 100%;
      max-width: unit(48.5);
      height: unit(64);
    }
  }
  
  .influencer__product-card-title {
    color: palette(greyscale-level-3);
    font-weight: 375;
    font-size: unit(3);
    line-height: unit(4);
    margin: 0;
    padding: unit(2);
    display: flex;
    justify-content: space-between;
    text-decoration: none;
  }
  
  .influencer__closing {
    background-color: palette(primary-extra-dark);
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  
    h1,
    h2,
    h3,
    p,
    .influencer__closing-link {
      color: palette(greyscale-level-7);
    }
  }
  
  .influencer__closing-right-image {
    display: none;
  
    @include respond-to(desktops) {
      display: block;
      height: 100%;
      position: absolute;
      right: 0px;
    }
  }
  
  .influencer__closing-left-image {
    display: none;
  
    @include respond-to(desktops) {
      display: block;
      height: 100%;
      position: absolute;
      left: 0px;
    }
  }
  
  .influencer__closing-title {
    @include influencer-title();
  
    position: relative;
    z-index: 30;
    margin: 0 auto;
    max-width: unit(202.75);
    text-align: center;
    padding-top: unit(16);
    padding-right: unit(2.5);
    padding-left: unit(2.5);
  
    @include respond-to(desktops) {
      padding-top: unit(24);
    }
  }
  
  .influencer__closing-description {
    @include influencer-description();
    max-width: unit(69.5);
    padding-top: unit(8);
    padding-bottom: unit(8);
    text-align: center;
  
    @include respond-to(desktops) {
      padding-right: 0;
      padding-left: 0;
      max-width: unit(158);
    }
  }
  
  .influencer__closing-cta {
    width: fit-content;
    margin: 0 auto;
    padding-bottom: unit(16);
  
    @include respond-to(desktops) {
      padding-bottom: unit(24);
    }
  }
  
  .influencer__inspiration {
    background-color: palette(primary-extra-dark);
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    color: palette(greyscale-level-7);
  }
  
  .influencer__inspiration-content {
    @include influencer-max-width;
    padding: unit(12) unit(4) unit(10.5);
    box-sizing: border-box;
    overflow: hidden;
  
    @include respond-to(wide) {
      display: flex;
      padding-bottom: unit(19);
      padding-top: unit(13.5);
    }
  }
  
  .influencer__inspiration-cards-wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: calc(100vw - unit(5));
    display: block;
    overflow: visible;
    margin-bottom: unit(0);
    margin-top: unit(4);
    margin-bottom: unit(12);
  
    .slider-wrapper-item {
      padding-right: unit(2);
    }
    .influencer__product-card {
      width: unit(48.5);
    }
    .influencer__product-card-image {
      width: 100%;
      height: unit(62.5);
    }
  
    @include respond-to(wide) {
      display: flex;
      flex-direction: row;
      justify-content: left;
      margin-bottom: unit(0);
      min-width: unit(153.5);
      max-width: unit(153.5);
      padding-left: 0;
      margin-left: unit(26.5);
      gap: unit(4);
  
      .slider-wrapper-item {
        padding-right: unit(4);
      }
    }
    .influencer__product-card-title {
      color: #b8b8b8;
    }
  }
  
  .influencer__inspiration-title {
    font-size: unit(6);
    line-height: unit(8);
    font-weight: 500;
    margin-bottom: unit(5.5);
  }
  
  .influencer__inspiration-description {
    line-height: unit(5.5);
    font-size: unit(4);
  }
  
  .influencer__inspiration-cta-headline {
    margin: 0;
    font-size: unit(6);
    line-height: unit(8);
    margin-bottom: unit(8);
  
    @include respond-to(wide) {
      margin-top: unit(16);
    }
  }
  
  .influencer__inspiration-cta {
    width: fit-content;
  }
  