// scss-lint:disable NestingDepth, QualifyingElement, SelectorDepth

.box--item-details {
  margin-top: 0;

  @include respond-to(tablets) {
    display: table;
    box-sizing: border-box;
    width: 100%;
  }

  .details-list__item {
    font-weight: $font-weight-demibold;
    line-height: $line-height-medium;
    color: palette(greyscale-level-3);
  }

  .details-list--main-info {
    @include respond-to(tablets) {
      display: table;
      width: 100%;
    }
  }

  .details-list--pricing {
    @include respond-to(tablets) {
      display: table-cell;
      float: right;
      width: 40%;
    }
  }

  .details-list--details {
    @include font-smoothing;
    margin-top: $half-gutter;
    display: flex;
    flex-direction: column;
    gap: $quarter-gutter;

    @include respond-to(tablets) {
      display: inline-block;
      width: calc(60% - #{$gutter});
      margin-top: 0;
    }

    .details-list__item-title,
    .details-list__item-value {
      @include word-wrap;
      display: inline-block;
      box-sizing: border-box;
      width: calc(50% - #{$quarter-gutter});
      line-height: $line-height-normal;
    }

    .details-list__item-title {
      font-weight: $font-weight-demibold;
      color: palette(greyscale-level-3);
      text-transform: uppercase;
      vertical-align: top;
    }

    .details-list__item-value {
      font-weight: $font-weight-demibold;
      color: palette(greyscale-level-3);
      text-transform: uppercase;
    }

    .details-list__item-value--show-more-button {
      text-transform: uppercase;
      text-align: initial;
      font-weight: 500;
      color: palette(primary-default);

      &:hover {
        text-decoration: underline;
      }
    }

    .details-list__item-value--expandable {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .details-list--actions {
    @include respond-to(tablets) {
      display: inline-block;
      float: right;
      width: 40%;
    }
  }

  .details-list__info {
    padding: unit(4) $gutter 0;
    margin: $gutter -#{$gutter} 0;
    border-top: 1px solid $box-line-color;

    .details-list__item-title {
      @include font-size($font-size-large);
      margin-bottom: $quarter-gutter;
      line-height: $line-height-medium;
    }

    .details-list__item-value {
      @include word-wrap();
      @include font-size($font-size-medium);
      line-height: $line-height-huge;

      p {
        color: palette(greyscale-level-3);
      }
    }

    .details-list--shipping-info > div:first-of-type {
      padding-top: unit(4)
    }
  }

  .details-list__info--without-title {
    padding: unit(2) $gutter 0;
  }

  .details-list--badges {
    @include font-smoothing;

    .details-list__item-value {
      display: block;
      padding-left: 25px;
      margin-bottom: $quarter-gutter;

      &:before {
        margin-left: -25px;
      }
    }
  }
}
