.seller-insights-photo-recommendations__item {
  box-sizing: border-box;
  display: inline-block;
  position: relative; 
  width: max-content; 
  height: max-content; 
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(unit(48), max-content)); /* Image size */
  gap: 10px;
  justify-content: center;
}

.seller-blocks-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(unit(65), 1fr)); /* Allows items to grow */
  gap: unit(4);
  width: 100%; 
}

.seller-insights-atom {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: unit(10);
  min-height: unit(45);

  &.seller-insights-tips {
    justify-content: space-between;
    gap: 0;
  }

  @include respond-to(portables) {
    gap: unit(8);
    min-height: unit(54);
  }
}

.seller-insights-tips-icon {
  background-color: palette-light(ca10);
  width: unit(10);
  height: unit(10);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

@include respond-to(wide) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
  }
}
