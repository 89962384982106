.follow-grid {
  display: grid;
  grid-template-columns: 1fr;

  @include respond-to(tablets-up) {
    grid-template-columns: 1fr 1fr;
    gap: 0px unit(8);
  }
}

.follow {
  position: relative;
  padding-top: unit(4);
  padding-bottom: unit(4);
  display: flex;
  justify-content: space-between;
}

.follow__name {
  @include font-size($font-size-medium);
  line-height: $line-height-normal;
  color: palette(greyscale-level-3);
  text-decoration: none;
}

// This is a workaround to avoid space added by `overflow: hidden` (`truncate` prop of `Text`)
// https://stackoverflow.com/questions/23529369/why-does-x-overflowhidden-cause-extra-space-below
.follow__name > * {
  vertical-align: top;
}

.follow__details {
  transition: all 300ms ease-in-out;
  transform: translateY(0);

  position: relative;
  opacity: 1;

  .is-active & {
    transform: translateY(80%);
    opacity: 0;
  }
}

.follow__status {
  transform: translateY(-20%);
  transition: all 300ms ease-in-out;

  position: absolute;
  opacity: 0;
  display: flex;
  gap: unit(1);

  .is-active & {
    transform: translateY(-100%);
    opacity: 1;
  }
}
