.multiple-collections-block {
  @include respond-to(phones) {
    padding: 0 unit(4);
  }
}

.multiple-collections-block__scroll-item {
  box-sizing: border-box;
  padding-right: unit(3);
  flex-shrink: 1;
  width: fractional-width(2.5);
  min-width: fractional-width(2.5);

  @include respond-to(tablets-up) {
    width: fractional-width(3.5);
    min-width: fractional-width(3.5);
    padding-right: unit(4);
  }

  @include respond-to(desktops) {
    width: fractional-width(4.5);
    min-width: fractional-width(4.5);
  }

  @include respond-to(wide) {
    width: fractional-width(5.5);
    min-width: fractional-width(5.5);
  }
}

.multiple-collections-block__square-box-container {
  overflow: hidden;
  border-radius: unit(1);
  aspect-ratio: 1 / 1;
}

.multiple-collections-block__square-box-container--inactive {
  opacity: $disabled-state-opacity;
}

.multiple-collections-block__collage {
  display: grid;
  gap: unit(0.5);
  grid-template-areas:
  "image1 image2"
  "image1 image3";
}

.multiple-collections-block__collage-image {
  &.collage--image-1 {
    grid-area: image1;
  }

  &.collage--image-2 {
    grid-area: image2;
  }

  &.collage--image-3 {
    grid-area: image3;
  }
}

.multiple-collections-block__box-content {
  position: absolute;
  top: 0;
  left: 0;
}

.multiple-collections-block__box-title {
  @include multiline-ellipsis(2);
}
