$profile-sidebar-width: 300px;

.profile {
  margin: 0 (-(map-get($grid-gutters, phones)));

  @include respond-to(tablets-up) {
    display: flex;
    margin: $gutter 0;
  }
}

.profile__items-wrapper {
  flex: 1 1 auto;

  @include respond-to(tablets-up) {
    padding: unit(0.5);
  }
}

.profile__user-description {
  @include word-wrap;
  word-break: normal;
}
