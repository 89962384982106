.satisfaction-survey__question-box {
  @include clearfix;

  padding-bottom: $half-gutter;
  margin-bottom: $half-gutter;
  border-bottom: 1px palette(greyscale-level-6) solid;
}

.satisfaction-survey__question {
  @include font-size($font-size-medium);

  margin-bottom: $half-gutter;
  font-weight: 500;
  color: palette(greyscale-level-2);
}
