$layout-max-width-narrower: unit(157);
$layout-max-width-narrow: unit(211);
$layout-max-width-regular: unit(265.5);
$layout-max-width-wide: unit(318.5);
$layout-extra-h-gutter: unit(2.5);
$layout-spacing-xsmall: unit(3);
$layout-spacing-small: unit(6);
$layout-spacing: unit(8);
$layout-spacing-desktop: unit(12);
$layout-spacing-large: unit(12);
$layout-spacing-large-desktop: unit(16);

@mixin title {
  font-size: unit(8);
  font-weight: 600;
  line-height: unit(12);

  @include respond-to(tablets-up) {
    font-size: unit(10.5);
    line-height: unit(13);
  }
}

@mixin subtitle {
  font-size: unit(5.5);
  line-height: unit(7.5);
  font-weight: 600;
}

@mixin body {
  font-size: unit(5);
  line-height: unit(7);
  max-width: $layout-max-width-narrow;

  @include respond-to(tablets-up) {
    font-size: unit(5.5);
    line-height: unit(7.5);
  }
}

@mixin body-small {
  font-size: unit(4);
  line-height: unit(6.5);
}

@mixin section-layout($align: flex-start, $width: 100%) {
  display: flex;
  flex-direction: column;
  width: $width;
  align-items: $align;
}

@mixin section-padding-large($direction) {
  padding-#{$direction}: $layout-spacing-large;

  @include respond-to(tablets-up) {
    padding-#{$direction}: $layout-spacing-large-desktop;
  }
}

@mixin section-padding($direction) {
  padding-#{$direction}: $layout-spacing;

  @include respond-to(tablets-up) {
    padding-#{$direction}: $layout-spacing-desktop;
  }
}

.item-verification {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: unit(7); // $gutter is 20px so in total: unit(12) 
  padding: 0 $layout-extra-h-gutter;

  @include respond-to(tablets-up) {
    padding: 0;
  }

  h1,
  h2,
  h3,
  p {
    color: palette(greyscale-level-1);
    margin: 0;
  }
}

.item-verification__introduction {
  @include section-layout(center);

  max-width: $layout-max-width-narrow;
}

.item-verification__introduction-title {
  @include title;

  text-align: center;
}

.item-verification__introduction-description {
  @include body;
  @include section-padding(bottom);
  @include section-padding(top);
  text-align: center;
}

.item-verification__introduction-button {
  min-width: unit(60);
}

.item-verification__introduction-image {
  @include section-padding-large(top);
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}

.item-verification__video {
  @include section-layout;
  @include section-padding-large(top);

  max-width: $layout-max-width-regular;
}

.item-verification__video-title {
  @include title;
}

.item-verification__video-description {
  @include body;
  @include section-padding(top);
  @include section-padding(bottom);

  max-width: $layout-max-width-narrow;
}

.item-verification__video-iframe {
  width: 100%;
  aspect-ratio: 1.75;
  border: 0;
}

.item-verification__how-it-works {
  @include section-padding-large(top);
  @include section-padding-large(bottom);

  background-color: palette(greyscale-level-6);
  width: 100vw;
}

.item-verification__how-it-works-content {
  margin: 0 auto;
  padding-left: unit(5);
  padding-right: unit(5);
  max-width: $layout-max-width-wide;
}

.item-verification__how-it-works-title {
  @include title;
  @include section-padding(bottom);

  max-width: $layout-max-width-regular;
  margin: 0 auto !important;
}

.item-verification__how-it-works-steps {
  display: grid;
  grid-template-columns: 1fr;
  max-width: $layout-max-width-wide;
  row-gap: $layout-spacing;

  @include respond-to(tablets-up) {
    grid-template-columns: 1fr 1fr;
    column-gap: unit(8);
    row-gap: $layout-spacing-desktop;
  }
}

.item-verification__how-it-works-step {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: $layout-spacing-small;
}

.item-verification__how-it-works-step-image {
  width: 100%;
  border-radius: unit(2.5);
  overflow: hidden;

  @include respond-to(tablets-up) {
    border-radius: unit(4);
  }
}

.item-verification__how-it-works-step-index {
  @include subtitle;

  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: unit(11);
  height: unit(11);
  padding: unit(0.5) 0 0 0;
  margin: 0;
  border-radius: 50%;
  border: 1px solid palette(greyscale-level-1);
  color: palette(greyscale-level-1);
}

.item-verification__how-it-works-step-title {
  @include subtitle;

  padding-top: unit(2);
}

.item-verification__how-it-works-step-description {
  @include body-small;

  max-width: unit(103);
}

.item-verification__closing {
  @include section-padding-large(top);
  @include section-padding-large(bottom);

  background-color: palette(primary-dark);
  width: 100vw;

  h1,
  h2,
  h3,
  p,
  .item-verification__closing-link {
    color: palette(greyscale-level-7);
  }

  @include respond-to(wide) {
    background-image: url(https://static-assets.vinted.com/images/landing/item-verification/closing-1.png), url(https://static-assets.vinted.com/images/landing/item-verification/closing-2.png);
    background-position: right bottom, left bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: contain;
  }
}

.item-verification__closing-content {
  @include section-layout(center, auto);

  margin: 0 auto;
  padding-left: unit(5);
  padding-right: unit(5);
  max-width: $layout-max-width-narrow;
  position: relative;
}

.item-verification__closing-title {
  @include title;

  max-width: $layout-max-width-narrower;
  margin: 0 auto !important;
  text-align: center;
}

.item-verification__closing-description {
  @include body;
  @include section-padding(top);
  @include section-padding(bottom);

  text-align: center;
}

.item-verification__closing-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $layout-spacing;
}

.item-verification__reviews {
  @include section-padding-large(top);
  @include section-padding-large(bottom);

  width: 100vw;
  position: relative;
  overflow-x: hidden;
}

.item-verification__reviews-title {
  @include title;
}

.item-verification__reviews-content {
  max-width: $layout-max-width-regular;
  margin: unit(0) auto;
  padding: unit(0) unit(5);
}

.item-verification__reviews-caption {
  @include section-padding(top);

  @include respond-to(tablets-up) {
    padding: 0;
    max-width: unit(104);
    margin-left: auto;
    height: 0;
  }
}

.item-verification__reviews-note {
  @include respond-to(tablets-up) {
    transform: translateY(-100%);
  }
}

.item-verification__reviews-card {
  border-radius: 20px;
  background-color: palette-light(br20);
  display: flex;
  flex-direction: column;
  gap: unit(3);
  padding: unit(5);
  box-sizing: border-box;
  width: 71vw;
  min-height: unit(95);

  @include respond-to(tablets-up) {
    padding: unit(8);
    width: unit(108);
    min-height: unit(126);
  }
}

.item-verification__reviews-card-image {
  width: unit(28.75);
  height: unit(28.75);
  align-self: center;
}

.item-verification__reviews-card-title {
  @include body-small;

  font-weight: 600;
}

.item-verification__reviews-card-review {
  @include body-small;

  @include respond-to(tablets-up) {
    font-size: unit(6);
    line-height: unit(9);
  }
}

.item-verification__value-proposition {
  @include section-padding-large(top);
  @include section-padding-large(bottom);

  width: 100vw;
  position: relative;
  overflow-x: hidden;
}

.item-verification__value-proposition-title {
  @include title;
}

.item-verification__value-proposition-content {
  max-width: $layout-max-width-regular;
  margin: unit(0) auto;
  padding: unit(0) unit(5);
}

.item-verification__value-proposition-content--slider > div > div:last-child {
  padding-bottom: 0;
  justify-content: flex-end;

  > button {
    margin-right: 0;
    margin-left: unit(5);
  }

  @include respond-to(tablets-up) {
    display: none;
  }
}

.item-verification__value-proposition-content--slider > div > div > div {
  @include respond-to(tablets-up) {
    & > div {
      padding-right: 0;
    }
    
    gap: unit(8);
    grid-auto-columns: minmax(0, 1fr);
  }
}

.item-verification__value-proposition-card {
  flex: 1;
  border-radius: 20px;
  background-color: palette-light(br20);
  display: flex;
  flex-direction: column;
  gap: unit(3);
  padding: unit(5);
  box-sizing: border-box;
  height: 100%;
  width: 71vw;
  min-height: unit(95);

  @include respond-to(tablets-up) {
    padding: unit(8);
    width: 100%;
    min-height: unit(122.5);
  }
}

.item-verification__value-proposition-card-image {
  width: 100%;
  max-width: unit(50);
  align-self: center;
  padding-bottom: unit(6);
  margin-top: unit(-10);

  @include respond-to(tablets-up) {
    margin-top: unit(-13);
  }
}

.item-verification__value-proposition-card-title {
  @include subtitle;

  height: unit(15.5);
}

.item-verification__value-proposition-card-description {
  @include body-small;
}

.item-verification__value-proposition-card-link {
  @include body-small;

  display: flex;
  gap: unit(2);
  margin-top: auto;
  padding-top: unit(8);
  height: unit(7);
  color: palette(greyscale-level-1);
  text-decoration: none !important;
  font-weight: 600;
}

.item-verification__value-proposition-card-link:hover {
  gap: unit(4);
}

.item-verification__value-proposition-card-link-icon {
  align-self: flex-end;
  display: inline-block;
}

.item-verification__note {
  @include section-padding-large(top);

  @include respond-to(tablets-up) {
    @include section-padding(top);
  }
}
