$pickup-point-map-controls-top: 0;
$pickup-point-map-controls-right: 0;
$pickup-point-map-controls-padding: unit(2);

$pickup-point-map-location-pin-medium-width: unit(8);
$pickup-point-map-location-pin-medium-height: unit(8);

$pickup-point-map-location-pin-background-color: palette(greyscale-level-6);

$pickup-point-map-address-pin-width: unit(6);
$pickup-point-map-address-pin-height: unit(6);
$pickup-point-map-address-pin-fill: palette(greyscale-level-7);

$pickup-point-map-current-location-inside-circle-stroke: palette(greyscale-level-7);
$pickup-point-map-current-location-inside-circle-stroke-width: unit(1);

$pickup-point-map-search-area-button-margin: unit(2);

$pickup-point-map-info-window-width: unit(75);
$pickup-point-map-info-window-height: unit(25);
