.homepage {
  position: relative;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.homepage-block-page-header {
  height: 48px;
}

.homepage-tabs {
  height: $header-tabs-height;
  position: sticky;
  z-index: map-get($zindex, large);
  top: $header-total-height;
  background-color: palette(greyscale-level-7);

  // TODO: These styles are copied from .container class. Create reusable mixin for this
  width: 100%;
  max-width: 100%;
  padding-right: $gutter;
  padding-left: $gutter;

  @include respond-to(phones) {
    padding-right: map-get($grid-gutters, phones);
    padding-left: map-get($grid-gutters, phones);
  }

  @include respond-to(portables) {
    min-width: 0;
    width: auto;
  }

  @include respond-to(tablets) {
    margin: 0;
  }
}

.homepage-tabs__content {
  @include hide-scrollbar;

  display: flex;
  gap: 16px;
  margin: 0 auto;
  max-width: $page-width-wide;
  border-bottom: 1px solid palette-light(greyscale-level-1, 0.08);
  overflow-y: scroll;
  scroll-behavior: smooth;
}

$homepage-top-banner-gap: 16px;

.homepage-top-banners {
  display: flex;
  flex-direction: column;
  gap: $homepage-top-banner-gap;
}

.homepage-top-banners > div:last-child {
  padding-bottom: $homepage-top-banner-gap;
}
