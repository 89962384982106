.payments-identity-status__container {
  display: flex;
  padding: $payments-identity-status-container-padding;
  justify-content: center;
  align-items: center;
}

.payments-identity-status__content {
  display: flex;
  max-width: $payments-identity-status-content-max-width;
  flex-direction: column;
}
