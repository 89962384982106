// Wrapper for modal dialog content
// TODO: deprecate dialog in favour of modal. Until then, bring dialog closer to modal visually.

.dialog-content {
  box-sizing: border-box;
  max-width: $dialog-content-width;
  padding: $dialog-content-padding;
  margin: 0 auto;
  background: palette(greyscale-level-7);
  border-radius: $fancybox-border-radius;
  box-shadow: 0 1px 1px palette-light(greyscale-level-1, 0.1);

  @include respond-to(phones) {
    width: auto;
    max-width: 100%;
    padding: $dialog-content-mobile-padding;
    border-radius: 0;
    background: none;
    border: none;
    box-shadow: none;
  }

  .form-actions {
    text-align: center;
    background: none;
    padding: 0;
    margin: 0;

    fieldset {
      margin: 0 auto;
    }
  }

  hr[data-title] {
    margin: 10px 0 20px;
    position: relative;
    height: $font-size-small;
    text-align: center;

    &:after {
      @include content('inline-block', attr(data-title));
      @include font-size($font-size-small);
      top: 4px;
      right: 0;
      left: 0;
      padding: 0 5px;
      font-weight: bold;
      color: palette(greyscale-level-3);
      text-transform: uppercase;
      white-space: nowrap;
      background: RGB($color-background-site);
    }
  }

  #{$text-inputs-inline} {
    @include text-field-medium;
  }

  .dropdown & {
    padding: 10px 0 0 0;
    border: none;
    box-shadow: none;

    #{$text-inputs-inline} {
      @include text-field-default;
    }
  }
}
