.shipping-discount-banner {
  border-radius: unit(1);
  overflow: hidden;
  background-color: palette-light(ca10);

  &--dark {
    background-color: palette-light(ca100);
  }

  strong {
    font-weight: 500;
  }
}
