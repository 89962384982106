$secondhand-taxes-text-color: palette(greyscale-level-1);
$secondhand-taxes-border: solid 1px palette(greyscale-level-5);
$secondhand-proof-image-width: unit(108);

@mixin secondhand-taxes-text-spacing($direction) {
  padding-#{$direction}: unit(8);

  @include respond-to(desktops) {
    padding-#{$direction}: unit(12);
  }
}

@mixin secondhand-taxes-text-color {
  color: $secondhand-taxes-text-color;
}

@mixin secondhand-taxes-break-layout {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}

@mixin secondhand-taxes-section-spacing-large($direction) {
  padding-#{$direction}: unit(12);

  @include respond-to(desktops) {
    padding-#{$direction}: unit(16);
  }
}

@mixin secondhand-taxes-section-spacing($direction) {
  padding-#{$direction}: unit(8);

  @include respond-to(desktops) {
    padding-#{$direction}: unit(12);
  }
}

@mixin secondhand-taxes-center-item {
  margin: unit(0) auto;
}

@mixin secondhand-taxes-page-max-width {
  max-width: unit(264.5);
}

@mixin secondhand-taxes-page-center-item {
  margin-right: auto;
  margin-left: auto;
}

@mixin secondhand-taxes-title(
  $size-desktop: unit(13.5),
  $height-desktop: unit(17.5)
) {
  color: $secondhand-taxes-text-color;
  font-weight: 500;
  font-size: unit(9.5);
  line-height: unit(12.5);
  text-align: left;

  @include respond-to(tablets-up) {
    font-size: unit(12);
    line-height: unit(14.5);
    text-align: center;
  }
}

@mixin secondhand-taxes-caption {
  color: $secondhand-taxes-text-color;
  font-size: unit(4.5);
  line-height: unit(5.5);
  text-align: left;

  @include respond-to(tablets-up) {
    font-size: unit(5.5);
    line-height: unit(7.5);
  }
}

@mixin secondhand-taxes-introduction-side-padding() {
  padding-right: 0%;
  padding-left: 0%;

  @include respond-to(tablets-up) {
    padding-right: 10%;
    padding-left: 10%;
  }
}

.secondhand-taxes__wrapper {
  padding: unit(0) unit(2.5);

  @include respond-to(tablets-up) {
    padding: 0;
  }
}

.secondhand-taxes__introduction {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  @include respond-to(desktops) {
    @include secondhand-taxes-page-max-width;
    @include secondhand-taxes-page-center-item;
  }
}

.secondhand-taxes__introduction-title {
  color: palette(greyscale-level-1);
  text-align: left;
  font-weight: 500;
  font-size: unit(8);
  line-height: unit(12);
  padding-top: unit(8);
  padding-bottom: unit(4);

  @include respond-to(tablets-up) {
    text-align: center;
    font-size: unit(10.5);
    line-height: unit(13);
    padding-top: unit(12);
    padding-bottom: unit(6);
  }
}

.secondhand-taxes__introduction-description {
  color: palette(greyscale-level-1);
  margin: 0;
  text-align: left;
  font-weight: 375;
  font-size: unit(4.5);
  line-height: unit(7);
  padding-bottom: unit(12);

  @include respond-to(tablets-up) {
    text-align: center;
    padding-right: 10%;
    padding-left: 10%;
    line-height: unit(7.5);
    max-width: 65%;
  }

  @include secondhand-taxes-introduction-side-padding();
}

.secondhand-taxes__introduction-cta {
  @include secondhand-taxes-section-spacing-large(bottom);
  width: 100%;

  a {
    padding-right: unit(5.5);
    padding-left: unit(5.5);

    @include respond-to(desktops) {
      width: fit-content;
      margin: unit(0) auto;
    }
  }
}

.secondhand-taxes__facts-cards-section {
  @include secondhand-taxes-center-item;
  padding-top: unit(12);
  padding-bottom: unit(12);

  @include respond-to(desktops) {
    padding-top: unit(16);
    padding-bottom: unit(16);
    @include secondhand-taxes-page-max-width;
    @include secondhand-taxes-page-center-item;
  }
}

.secondhand-taxes__facts-card-section-title {
  color: palette(greyscale-level-1);
  font-weight: 500;
  font-size: unit(8);
  line-height: unit(12);
  text-align: center;

  @include respond-to(tablets-up) {
    font-size: unit(10.5);
    line-height: unit(13);
    padding-bottom: unit(12);
  }
}

.secondhand-taxes__facts-cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: unit(4);

  @include respond-to(desktops) {
    align-items: flex-start;
    gap: 6px;
    flex-direction: row;
  }
}

.secondhand-taxes__facts-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include respond-to(desktops) {
    width: 100%;
    max-width: 330px;
  }
}

.secondhand-taxes__facts-card-caption {
  color: palette(greyscale-level-1);
  margin: 0;
  text-align: center;
  font-weight: 375;
  font-size: unit(4.5);
  line-height: unit(6);
  max-width: unit(70.5);
  padding-bottom: unit(6);
}

.secondhand-taxes__facts-card-title {
  color: palette(greyscale-level-1);
  margin: 0;
  max-width: unit(70.5);
  text-align: center;
  font-weight: 500;
  font-size: unit(6);
  line-height: unit(7.5);
  padding-bottom: unit(6);
}

.secondhand-taxes__facts-card-image {
  margin: 0;
  width: unit(48.5);
  height: unit(31);
  padding-top: unit(6);
  padding-bottom: unit(6);
}

.secondhand-taxes__banner-section {
  @include secondhand-taxes-break-layout;
  background-color: palette(primary-default);
  color: palette(greyscale-level-7);
}

.secondhand-taxes__banner-section-inner {
  @include secondhand-taxes-center-item;
  @include secondhand-taxes-page-max-width;
  @include secondhand-taxes-section-spacing-large(top);
  @include secondhand-taxes-section-spacing-large(bottom);

  position: relative;
  padding-left: unit(5);
  padding-right: unit(5);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  @include respond-to(desktops) {
    padding-left: unit(16);
    padding-right: unit(16);
    max-width: unit(275);
    flex-direction: column;
    justify-content: space-between;
  }
}

.secondhand-taxes__banner-section-image {
  display: none;

  @include respond-to(desktops) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    text-align: right;
    height: 100%;
    width: 100%;
  }

  img {
    object-position: right center;
  }
}

.secondhand-taxes__banner-section-title {
  @include secondhand-taxes-title(unit(8), unit(12));

  font-weight: 500;
  max-width: unit(90);
  color: palette(greyscale-level-7);
  text-align: left;
  margin: 0;

  @include respond-to(tablets-up) {
    max-width: unit(108.5);
    text-align: left;
    padding-bottom: unit(8);
  }
}

.secondhand-taxes__banner-section-caption {
  @include secondhand-taxes-caption;

  font-weight: 375;
  color: palette(greyscale-level-7);
  max-width: unit(108.5);
  padding-bottom: unit(6);

  @include respond-to(tablets-up) {
    padding-top: unit(4);
    padding-bottom: unit(6);
  }
}

.secondhand-taxes__examples-section {
  @include respond-to(portables) {
    background: palette-light(br20);
    @include secondhand-taxes-break-layout;
    padding-bottom: unit(12);
  }

  @include respond-to(desktops) {
    @include secondhand-taxes-page-max-width;
    @include secondhand-taxes-page-center-item;
  }
}

.secondhand-taxes__examples-section-title {
  text-align: center;
  color: $secondhand-taxes-text-color;
  font-size: unit(8);
  line-height: unit(12);
  font-weight: 500;

  @include secondhand-taxes-text-spacing(bottom);
  @include secondhand-taxes-text-spacing(top);

  @include respond-to(desktops) {
    padding-top: unit(0);
    text-align: center;
    font-size: unit(10.5);
  }
}

.secondhand-taxes__examples-container {
  padding-right: unit(5);
  padding-left: unit(5);
  @include respond-to(desktops) {
    padding: 0;
  }
}

.secondhand-taxes__examples-card {
  margin-bottom: unit(18);
  width: 100%;

  &:first-of-type {
    margin-top: unit(6);
  }
  &:last-of-type {
    margin-bottom: 0;
  }

  @include respond-to(desktops) {
    background: palette-light(br20);
    border-radius: unit(4);
    margin-bottom: unit(12);
    box-sizing: border-box;
    display: flex;
    padding: unit(17) unit(13.5) unit(0);
    &:first-of-type {
      margin-top: unit(0);
    }
  }
}

.secondhand-taxes__examples-card-infobox {
  background: palette(greyscale-level-7);
  border-radius: unit(2);
  padding: unit(5) unit(5) unit(6) unit(5);
  margin-bottom: unit(6);

  @include respond-to(desktops) {
    margin-bottom: 0;
    min-width: unit(97);
    border-radius: unit(2) unit(2) 0 0;
    padding: unit(7.5) unit(8) unit(15);
  }
}

.secondhand-taxes__examples-card-infobox-header {
  display: flex;
  justify-content: space-between;
  margin-top: unit(-11);
  margin-bottom: unit(8);

  @include respond-to(desktops) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-top: unit(-16.5);
  }
}

.secondhand-taxes__examples-card-infobox-header-user {
  align-self: flex-end;

  @include respond-to(desktops) {
    margin-left: unit(6);
  }
}

.secondhand-taxes__examples-card-infobox-header-name {
  font-size: unit(4);
  line-height: unit(6.5);
  font-weight: 500;
  color: $secondhand-taxes-text-color;
  margin: 0;

  @include respond-to(desktops) {
    font-size: unit(4.5);
  }
}

.secondhand-taxes__examples-card-infobox-header-description {
  font-size: unit(3);
  line-height: unit(4);
  color: palette(greyscale-level-3);
  margin: 0;
}

.secondhand-taxes__examples-card-infobox-header-image {
  width: unit(28.5);
  height: unit(28.5);
  border-radius: 100%;
  overflow: hidden;
}

.secondhand-taxes__examples-card-infobox-row {
  display: flex;
  justify-content: space-between;
  padding-top: unit(2);
  padding-bottom: unit(2);
  border-bottom: $secondhand-taxes-border;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
    .secondhand-taxes__examples-card-infobox-row-title {
      font-size: unit(5);
      line-height: unit(7);
    }
    .secondhand-taxes__examples-card-infobox-row-value {
      font-size: unit(5);
      line-height: unit(7);
      color: palette(success-default);
    }
  }

  @include respond-to(desktops) {
    padding-top: unit(3);
    padding-bottom: unit(3);

    &:last-of-type {
      .secondhand-taxes__examples-card-infobox-row-title,
      .secondhand-taxes__examples-card-infobox-row-value {
        font-size: unit(6);
        line-height: unit(8);
      }
    }
  }
}

.secondhand-taxes__examples-card-infobox-row-title {
  font-size: unit(4);
  line-height: unit(6.5);
  font-weight: 375;
  color: palette(shadow);

  @include respond-to(desktops) {
    font-size: unit(4.5);
  }
}

.secondhand-taxes__examples-card-infobox-row-value {
  font-size: unit(4);
  line-height: unit(5.5);
  font-weight: 500;
  color: $secondhand-taxes-border;

  @include respond-to(desktops) {
    font-size: unit(4.5);
    line-height: unit(6.5);
  }
}

.secondhand-taxes__examples-card-notes {
  text-align: center;

  @include respond-to(desktops) {
    text-align: left;
    padding-top: unit(7);
    padding-left: unit(21.5);
  }
}

.secondhand-taxes__examples-card-notes-header {
  color: $secondhand-taxes-text-color;
  font-size: unit(5.5);
  line-height: unit(7.5);
  font-weight: 500;
  margin: unit(3) 0;

  @include respond-to(desktops) {
    font-size: unit(8);
    line-height: unit(10.5);
  }
}

.secondhand-taxes__examples-card-notes-sub_header {
  color: palette(greyscale-level-2);
  font-size: unit(4);
  line-height: unit(7);
  font-weight: 375;

  @include respond-to(desktops) {
    font-size: unit(4.5);
    line-height: unit(8);
    color: palette(greyscale-level-3);
  }
}

.secondhand-taxes__examples-card-notes-description {
  color: palette(greyscale-level-2);
  font-size: unit(4);
  line-height: unit(6);
  font-weight: 375;

  @include respond-to(desktops) {
    font-size: unit(4.5);
    line-height: unit(7);
    color: palette(greyscale-level-1);
  }
}

.secondhand-taxes__questions-section {
  @include respond-to(desktops) {
    @include secondhand-taxes-page-max-width;
    @include secondhand-taxes-page-center-item;
  }
}

.secondhand-taxes__questions-section-title {
  text-align: center;
  color: $secondhand-taxes-text-color;
  font-size: unit(8);
  line-height: unit(12);
  font-weight: 500;

  @include secondhand-taxes-text-spacing(bottom);
  @include secondhand-taxes-text-spacing(top);

  @include respond-to(desktops) {
    font-size: unit(10.5);
    line-height: unit(12.75);
  }
}

.secondhand-taxes__questions-section-inner {
  display: flex;
  flex-direction: column;
  padding-bottom: unit(8);
  gap: unit(14);

  @include respond-to(desktops) {
    flex-direction: row;
    gap: 0;
  }
}

.secondhand-taxes__questions-section-card {
  @include respond-to(desktops) {
    width: 50%;
    padding: 0 unit(14);
  }
}

.secondhand-taxes__questions-section-card-image {
  max-width: unit(61);
  margin-bottom: unit(6);
}

.secondhand-taxes__questions-section-card-title {
  font-size: unit(5.5);
  line-height: unit(7.5);
  color: $secondhand-taxes-text-color;
  margin-bottom: unit(4);
}

.secondhand-taxes__questions-section-card-description {
  font-size: unit(4);
  line-height: unit(6.5);
  color: $secondhand-taxes-text-color;
  font-weight: 375;
  margin: 0;
}

.secondhand-taxes__tip-section {
  margin-top: unit(8);
  margin-bottom: unit(16);

  @include respond-to(desktops) {
    margin-top: unit(16);
    margin-bottom: unit(24);
    padding: 0 unit(14);
    box-sizing: border-box;

    @include secondhand-taxes-page-max-width;
    @include secondhand-taxes-page-center-item;
  }
}

.secondhand-taxes__tip-section-title {
  font-size: unit(5.5);
  line-height: unit(7.5);
  font-weight: 500;
  margin-bottom: unit(4);
  color: $secondhand-taxes-text-color;
}

.secondhand-taxes__tip-section-description {
  margin: 0;
  font-size: unit(4);
  line-height: unit(6.5);
  color: palette(greyscale-level-2);
}

.secondhand-taxes__listing-section {
  @include secondhand-taxes-break-layout;

  background: palette(primary-default);
  padding-top: unit(12);
  padding-bottom: unit(16);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond-to(desktops) {
    padding-top: unit(16);
    padding-bottom: unit(26);
  }
}

.secondhand-taxes__listing-section-title {
  font-size: unit(10.5);
  line-height: unit(14.5);
  font-weight: 500;
  color: palette(greyscale-level-7);
  margin-bottom: unit(12);
  text-align: center;
}

.secondhand-taxes__listing-section-cta {
  a {
    padding-right: unit(5);
    padding-left: unit(5);
  }
}

.secondhand-taxes__proof-section {
  @include secondhand-taxes-break-layout;
  @include secondhand-taxes-section-spacing-large(top);
  @include secondhand-taxes-section-spacing-large(bottom);
  background: palette(greyscale-level-6);

  @include respond-to(desktops) {
    margin-bottom: unit(16);
  }
}

.secondhand-taxes__proof-section-title {
  text-align: center;
  color: palette(greyscale-level-1);
  font-size: unit(8);
  font-weight: 500;
  line-height: unit(12);
}

.secondhand-taxes__proof-section-inner {
  padding-left: unit(5);
  padding-right: unit(5);
  @include secondhand-taxes-text-spacing(top);

  @include respond-to(desktops) {
    @include secondhand-taxes-page-max-width;
    @include secondhand-taxes-page-center-item;

    display: flex;
    margin-bottom: unit(8);
  }
}

.secondhand-taxes__proof-section-image {
  @include secondhand-taxes-page-center-item;
  display: flex;
  justify-content: center;
  margin-bottom: unit(8);

  img {
    border-radius: unit(2);
    width: 100%;
    max-width: $secondhand-proof-image-width;
  }

  @include respond-to(desktops) {
    margin: 0 unit(27) 0 0;

    img {
      width: $secondhand-proof-image-width;
    }
  }
}

.secondhand-taxes__proof-section-subtitle {
  color: palette(greyscale-level-1);
  font-size: unit(6);
  font-weight: 500;
  line-height: unit(7.5);
  margin-bottom: unit(6);

  @include respond-to(desktops) {
    margin-bottom: unit(4);
  }
}

.secondhand-taxes__proof-section-description {
  color: palette(greyscale-level-1);
  font-size: unit(4);
  line-height: unit(6);

  h4 {
    color: palette(greyscale-level-1);
    font-size: unit(6);
    font-weight: 500;
    line-height: unit(7.5);
    margin-bottom: unit(6);  
    margin-top: unit(8);

    @include respond-to(desktops) {
      margin-top: unit(9.5);
      margin-bottom: unit(4);  
    }
  }
}
