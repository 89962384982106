.conversation-loader__overlay {
  @include stretch();

  z-index: map-get($zindex, small);
  background-color: palette(greyscale-level-7, 0.7);
}

.conversation-loader {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
