$new-item-box-overlay-background: palette(greyscale-level-1, 0.7);
$new-item-box-default-background: palette(greyscale-level-5);
$new-item-box-description-line-heigth: 16px;

.new-item-box__container {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.new-item-box__title {
  font-weight: 500;

  .title-content {
    display: flex;
    align-items: center;
  }
}

.new-item-box__description {
  @include ellipsis;
  display: flex;

  line-height: $new-item-box-description-line-heigth;
}

.new-item-box__collage {
  display: grid;
  height: 100%;
  gap: unit(0.5);
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
  "image1 image2"
  "image1 image3";
}

.new-item-box__image {
  position: relative;
  background: $new-item-box-default-background;
  height: 100%;

  &.collage--image-1 {
    grid-area: image1;
  }

  &.collage--image-2 {
    grid-area: image2;
  }

  &.collage--image-3 {
    grid-area: image3;
  }
}

.new-item-box__image-overlay {
  @include stretch;

  display: flex;
  background-color: $new-item-box-overlay-background;
  justify-content: center;
  align-items: center;
}

.new-item-box__overlay {
  @include stretch;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &--clickable {
    cursor: pointer;
  }
}

a.new-item-box__overlay {
  text-decoration: none;
}

.new-item-box__overlay-icon {
  transition: all 0.3s ease;
}

.new-item-box__overlay-icon:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.new-item-box__favourite-icon {
  border-radius: 24px;
  padding: 8px;
  margin: 8px;
  border: 1px solid var(--v-sys-theme-greyscale-level-6, #F2F2F2)
}

.new-item-box__favourite-icon-overlay {
  margin-bottom: unit(12);
}

.new-item-box__favourite-icon:hover {
  background-color: palette(greyscale-level-6)
}
