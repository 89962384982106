.dropzone {
  position: relative;
  border: unit(0.25) palette(greyscale-level-5) dashed;
  border-radius: unit(1);
}

.dropzone__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($zindex, overlay);
  background-color: palette(greyscale-level-7);
  justify-content: center;
  align-items: center;
}

.dropzone__overlay-description {
  pointer-events: none;
}
