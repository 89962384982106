.action-header {
  @include sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: map-get($zindex, header);
  background-color: palette(greyscale-level-7);
  border-bottom: 1px solid $divider-color;
}

.action-footer {
  @include sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: map-get($zindex, header);
  background-color: palette(greyscale-level-7);
  border-top: 1px solid $divider-color;
}
