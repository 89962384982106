.breadcrumbs {
  display: flex;
  margin: 0 0 $breadcrumbs-bottom-spacing;
  list-style: none;
}

.breadcrumbs--truncated {
  @include type($breadcrumbs-item-text-type);
  @include ellipsis;
  display: block;

  .breadcrumbs__item {
    display: inline;
  }
}

.breadcrumbs__item {
  @include type($breadcrumbs-item-text-type);
  position: relative;
  display: flex;
  margin: 0 $breadcrumbs-item-space-between 0 0;

  &::before {
    padding-right: $breadcrumbs-item-space-between;
    color: $breadcrumbs-item-separator-color;
    content: '/';
  }

  &:first-child {
    &::before {
      display: none;
    }
  }

  > a {
    color: inherit;
  }
}
