@use "sass:math";

.closet {
  .closet__item {
    box-sizing: border-box;
    width: percentage(math.div(1, 3));
    padding: 0 unit(2);
  }
}

.closet--with-horizontal-scroll {
  overflow: hidden;

  .closet__item {
    box-sizing: border-box;
    min-width: fractional-width(2.2);
    flex-shrink: 1;

    @include respond-to(tablets-up) {
      min-width: fractional-width(3.5);
    }

    @include respond-to(wide) {
      min-width: fractional-width(4.5);
    }
  }

  .closet__item--collage:first-child {
    min-width: fractional-width(7, 6);

    @include respond-to(tablets-up) {
      min-width: fractional-width(6, 3);
    }

    @include respond-to(wide) {
      min-width: fractional-width(8, 3);
    }
  }

  .closet__item--collage {
    min-width: fractional-width(7, 4);

    @include respond-to(tablets-up) {
      min-width: fractional-width(6, 2);
    }

    @include respond-to(wide) {
      min-width: fractional-width(8, 2);
    }
  }
}

.closet--wide {
  .closet__item {
    @include respond-to(desktops) {
      min-width: fractional-width(4.5);
    }

    @include respond-to(wide) {
      min-width: fractional-width(5.5);
    }
  }

  .closet__item--collage:first-child {
    @include respond-to(desktops) {
      min-width: fractional-width(8, 3);
    }

    @include respond-to(wide) {
      min-width: fractional-width(10, 3);
    }
  }

  .closet__item--collage {
    @include respond-to(desktops) {
      min-width: fractional-width(8, 2);
    }

    @include respond-to(wide) {
      min-width: fractional-width(10, 2);
    }
  }
}

.closet__cta-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: palette(greyscale-level-6);
}

.closet__cta {
  display: flex;
  @include size(100%);
  position: absolute;
}
