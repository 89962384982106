@use "sass:math";

@keyframes fade-in-up {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
