.input-birthdate__content {
  flex-wrap: nowrap;

  > label {
    flex-basis: 25%;

    &:nth-child(2) {
      flex-basis: 50%;
    }

    &:not(:last-child) {
      padding-right: $input-padding;
    }
  }
}
