.item-photos {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: $item-photo-container-height-wide;
  padding: 0;
  margin: 0 auto;

  @include respond-to(phones) {
    height: $item-photo-container-height-phones;
  }

  @include respond-to(desktops) {
    height: $item-photo-container-height;
  }

  @include respond-to(wide) {
    .is-wide & {
      height: $item-photo-container-height-wide;
    }
  }

  .item-thumbnail {
    display: block;
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    margin: 0;
    color: transparent;
    background-position: center center;
    background-size: cover;

    @include respond-to(phones) {
      border-radius: 0;
      width: 100%;
    }

    .i {
      &:before {
        @include center;
        position: absolute;
      }
    }

    &[data-photoid] {
      cursor: pointer;
    }
  }

  .item-description {
    border-radius: 0;
  }
}

.item-photos__container {
  width: 100%;

  @include respond-to(phones) {
    margin-left: -$half-gutter;
    margin-top: -$gutter;
    width: calc(100% + #{$gutter});
  }
}

.item-favourite-icon {
  border-radius: 24px;
  padding: 8px;
  margin: 16px;
  border: 1px solid var(--v-sys-theme-greyscale-level-6, #F2F2F2);
}

.item-favourite-icon:hover {
  background-color: palette(greyscale-level-6)
}

.item-photo {
  @include respond-to(phones) {
    width: 100%;
  }
}

.item-photo,
.item-photo--3 ~ .item-photo--2 {
  position: absolute;
  top: 0;
  box-sizing: border-box;
  height: 50%;
  padding: 0;
  border-left: $item-photo-border;

  &:only-child {
    .item-thumbnail {
      margin: 0 auto;
    }
  }

  &:not(:only-child) {
    .item-thumbnail {
      width: 100%;
    }
  }
}

.item-photo:first-child {
  border-left: 0;
}

.item-photo--1 {
  left: 0;
  height: 100% !important;

  &:not(:last-child) {
    width: 50%;
  }
}

.item-photo--2 {
  left: 50%;
  width: 50%;
  height: 50%;

  &:last-child {
    height: 100%;
  }

  &:nth-last-child(4) {
    width: 25%;
  }
}

.item-photo--3 {
  top: 50%;
  left: 50%;
  width: 25%;

  &:last-child {
    width: 50%;
  }

  &:nth-last-child(3) {
    top: 0;
    left: 75%;
  }
}

.item-photo--3:last-child,
.item-photo--3:nth-last-child(2),
.item-photo--4,
.item-photo--5 {
  border-top: $item-photo-border;
}

.item-photo--4 {
  left: 50%;
  width: 25%;
}

.item-photo--4,
.item-photo--5 {
  top: 50%;
}

.item-photo--4:last-child,
.item-photo--5 {
  left: 75%;
  width: 25%;
  border-left: $item-photo-border;
}
