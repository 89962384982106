.header-avatar {
  display: inline-block;
  width: $header-avatar-size;
  height: $header-avatar-size;
  position: relative;
  max-width: 100%;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;

  &:before {
    @include arrow(bottom, $header-item-color, $arrow-small-size);
    @include content();

    top: 50%;
    left: ($header-avatar-size + $arrow-small-size + 2px);
    margin-top: -($arrow-small-size);

    @include respond-to(portables) {
      display: none;
    }
  }

  .open &:before,
  &:hover:before {
    @include arrow(bottom, $header-item-active-color, $arrow-small-size);
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

// User dropdown columns
.user-menu-groups {
  max-height: 80vh;
  white-space: nowrap;
  text-align: left;
  border-radius: $dropdown-border-radius;

  @include respond-to(desktops) {
    display: flex;
  }

  .nav-link:hover {
    background-color: palette(greyscale-level-1, 0.05);
  }
}

.user-menu-group {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: $gutter;
  white-space: nowrap;
  vertical-align: top;
  direction: ltr;

  @include respond-to(desktops) {
    min-width: 205px;
    padding: $quarter-gutter;
    margin: 0;
  }

  &:first-child {
    border-right: 0;
  }

  &:nth-child(even) {
    background-color: palette(greyscale-level-7);

    @include respond-to(desktops) {
      background-color: palette(greyscale-level-1, 0.02);
    }
  }
}

.user-menu-group__item:not(:last-child) {
  margin-bottom: $gutter;
}

.header__logo {
  position: absolute;
  z-index: map-get($zindex, bump);
  display: flex;
  color: palette(greyscale-level-7);
  align-items: center;
  @include center(horizontal);

  @include respond-to(tablets-up) {
    transform: none;
    position: relative;
    left: 0;
    float: left;
    margin-top: unit(1);
    margin-right: $gutter;
  }
}
