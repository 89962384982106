.dropdown-wide {
  min-width: $dropdown-wide-min-width;

  &.dropdown--fixed-width {
    width: $dropdown-wide-min-width;
  }
}

.dropdown-menu {
  position: absolute;
  right: 0;
  left: auto;
  z-index: map-get($zindex, dropdown);
  text-align: left;
}

.dropdown-menu--align-left {
  right: auto;
  left: 0;
}

.dropdown-menu--shadow {
  box-shadow: 0 8px 24px palette-light(greyscale-level-1, 0.24);
}

.dropdown-menu--rounded {
  border-radius: 4px !important;
  background: palette(greyscale-level-7);
  border: 1px solid palette-light(greyscale-level-1, 0.00);
  border-radius: 4px;
}
