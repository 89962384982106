.featured-collection-container {
  display: block;
  box-sizing: border-box;
  width: 100%;

  @include respond-to(tablets-up) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.featured-collection {
  width: 100%;
}

.featured-collection__items--inactive {
  opacity: $disabled-state-opacity;
}

.featured-collection__item {
  box-sizing: border-box;
  min-width: fractional-width(2.2);
  padding: 0 unit(1);

  @include respond-to(tablets-up) {
    min-width: fractional-width(3.5);
    padding: 0 unit(2);
  }

  @include respond-to(desktops) {
    min-width: fractional-width(4.5);
  }

  @include respond-to(wide) {
    min-width: fractional-width(5.5);
  }
}

.featured-collection__review-image {
  display: flex;
  justify-content: center;
  align-items: center;
  @include size(unit(32));
}

.featured-collection__review-image-container {
  position: relative;
}

.featured-collection__review-image-remove-button {
  position: absolute;
  top: unit(3);
  right: unit(3);
}
