@use "sass:math";

.item-view-items {
  display: flex;
  flex-wrap: wrap;
  margin: $item-view-items-container-margin-vertical $item-view-items-container-margin-horizontal;
}

.item-view-items__item {
  box-sizing: border-box;
  width: percentage(1*0.5);
  padding: 0 $item-view-items-item-padding-horizontal $item-view-items-item-padding-bottom;

  @include respond-to(tablets-up) {
    width: percentage(math.div(1, 3));
  }

  @include respond-to(wide) {
    width: percentage(1*0.25);
  }
}

.item-page-horizontal-block .horizontally-scrollable-items__cta-content {
  background: white;
}

.item-view-items-horizontal__cta .horizontal-scroll__item {
  width: 100%;
  height: 100%;
}

.item-view-items-horizontal__item {
  box-sizing: border-box;
  width: fractional-width(2.5);

  @include respond-to(tablets-up) {
    width: fractional-width(3);
  }

  @include respond-to(desktops) {
    width: fractional-width(4);
  }

  @include respond-to(wide) {
    width: fractional-width(4.5);
  }
}
