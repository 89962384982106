.item-thumbnail {
  object-fit: cover;

  height: 100%;
  border-radius: $media-border-radius;

  @include respond-to(phones) {
    width: 100%;
  }

  &:nth-of-type(n + 2) {
    display: none;
    box-sizing: border-box;
    height: 100%;
    background: center center;
    background-size: cover;
  }
}

.item-description {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 0;
  padding-bottom: 138%; // (IMAGE-HEIGHT) : (IMAGE-WIDTH) * 100%;
  overflow: hidden;
  border-radius: $catalog-item-radius;
}

.item-information {
  menu {
    margin: 0;
  }

  .content-wrapper-flex {
    flex-direction: row;
  }
}

.description-collapsed {
  max-height: unit(22);
  overflow: hidden;
}

.item-page-feed-loader {
  padding-top: unit(12);
  padding-bottom: unit(8);
}
