$image-carousel-z-index: map-get($zindex, overlay);
$image-carousel-background-color: palette-light(greyscale-level-1, 0.48);

$image-carousel-image-wrapper-height: 75vh;
$image-carousel-image-wrapper-width: 75vw;
$image-carousel-image-wrapper-background-color: palette(greyscale-level-1);

$image-carousel-image-max-width: 100%;
$image-carousel-image-max-height: 100%;

$image-carousel-button-height: 100%;
$image-carousel-button-padding: unit(2);
$image-carousel-button-opacity: 0.7;
$image-carousel-button-hover-opacity: 1;
$image-carousel-button-hover-transition: 0.25s opacity;

$image-carousel-icon-wrapper-padding: unit(2);
$image-carousel-icon-wrapper-border-radius: 100%;
$image-carousel-icon-wrapper-background-color: palette(greyscale-level-1, 0.3);
