@import 'abstracts/functions/general';
@import 'abstracts/functions/ui';

// Background colors

$color-background-site: palette-rgb(greyscale-level-6);
$color-background-white: palette(greyscale-level-7);
$color-background-light: palette(greyscale-level-6);

// borders
$color-border-light: palette(greyscale-level-5);
$color-horizontal-line-default: palette(greyscale-level-1, 0.05);

// Dimensions

$page-width: 960px;
$page-width-narrow: 680px;
$page-width-wide: 1280px;
$min-device-width: 240px;
$gutter: 20px;
$half-gutter: $gutter * 0.5;
$quarter-gutter: $gutter * 0.25;

// Font Sizes — 1 rem === 10px

$font-size-bigger: 3.4rem;
$font-size-big: 2.8rem;
$font-size-heading: 2.4rem;
$font-size-hero: 2.1rem;
$font-size-large: 1.8rem;
$font-size-medium: 1.5rem;
$font-size-default: 1.3rem;
$font-size-small: 1.1rem;
$font-size-micro: 0.9rem;

$line-height-huge: 1.5;
$line-height-large: 1.375;
$line-height-normal: 1.33333333;
$line-height-medium: 1.25;

// Font weights

$font-weight-demibold: 500;
$font-weight-bold: bold;

// Media query break points

$phone-breakpoint: 320px;
$tablet-breakpoint: 720px;
$desktop-breakpoint: 960px;
$wide-breakpoint: 1200px;

$tablets-mq: 'only screen and (min-width: #{$tablet-breakpoint + 1}) and (max-width: #{$desktop-breakpoint - 1})';
$tablets-up-mq: 'only screen and (min-width: #{$tablet-breakpoint + 1})';
$desktops-mq: 'only screen and (min-width: #{$desktop-breakpoint})';
$wide-mq: 'only screen and (min-width: #{$wide-breakpoint})';
$phones-mq: 'only screen and (max-width: #{$tablet-breakpoint})';
$retina-mq: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx), (min-resolution: 192dpi)';

// Header

$header-height: 52px;
$header-nav-height: 46px;
$header-borders-height: 2px;
$header-total-height: $header-height + $header-nav-height + $header-borders-height;
$header-avatar-size: 30px;
$header-shadow-color: palette-light(greyscale-level-1, 0.1);
$header-item-color: palette(greyscale-level-3);
$header-item-active-color: palette(greyscale-level-1);

// Animations

$default-animation-speed: 0.125s;

// Misc

$disabled-state-opacity: 0.48;

$default-nav-line-height: 25px;
$responsive-nav-line-height: 30px;
$catalog-item-radius: 3px;

// Move this to Box/base.scss

$box-shadow: 0 1px 1px palette-light(greyscale-level-1, 0.1);
$box-background: palette(greyscale-level-7);
$box-padding: $gutter;
$box-line-color: palette(greyscale-level-1, 0.05);
$box-line: 1px solid $box-line-color;
$box-border-radius: 2px;

$arrow-small-size: 3px;

$dialog-content-padding: 40px;
$dialog-content-mobile-padding: 20px;
$dialog-content-width: 420px;

$media-border-radius: 3px;

// Option tables

$text-inputs: 'input[type="file"], input[type="text"], input[type="tel"], input[type="email"], input[type="password"], input[type="url"], textarea';
$text-inputs-inline: 'input[type="text"], input[type="tel"], input[type="email"], input[type="password"], input[type="url"]';
$button-inputs: 'button, input[type="submit"], input[type="button"], input[type="image"]';
$inputs: 'input, button, textarea';

$input-height: 30px;
$input-font-size: inherit;
$input-border-radius: 3px;

$input-medium-height: 35px;
$input-medium-font-size: $font-size-medium;
$input-medium-border-radius: 4px;

$input-text-color: palette(greyscale-level-3);
$input-background-color: $color-background-site;
$input-border-color: $color-border-light;
$input-padding: 5px;

$invalid-input-message-border-color: palette(warning-medium);

// Dropdowns

$dropdown-border-radius: 3px;
$dropdown-nav-item-border-radius-size: 3px;
// Used often so in the future will be moved to global colors
$dropdown-nav-item-hover-color: palette(greyscale-level-1, 0.05);
$dropdown-wide-min-width: 300px;

// Fancybox

$fancybox-border-radius: $media-border-radius;

// Stacking order

$zindex: (
  negative            : -100,
  below               : -1,

  none                : 0,
  bump                : 1,

  small               : 5,
  medium              : 10,
  large               : 20,

  // map
  location-pin        : 100,
  address-pin         : 101,
  current-location    : 102,
  location-pin-active : 103,
  location-pin-hover  : 104,
  info-window         : 200,

  dropdown            : 100000,
  sticky              : 100005,
  header              : 100010,
  above-header        : 100015,
  overlay             : 100020,
  above-overlay       : 100025,
  notification        : 100030,

  // these are for fancybox...
  dialog-opened       : 100030,
  dialog-buttons      : 100035,
  dialog-content      : 100040,
  dialog-loading      : 100045,

  google-autocomplete : 100050,
);

// Grid settings

$grid-columns: 12;
$grid-gutters: (
  phones: unit(2)
);
