@font-face {
  font-family: V-Inter; 
  src: url('../../fonts/V-Inter-Regular-375.woff2') format('woff2'),
    url('../../fonts/V-Inter-Regular-375.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: V-Inter;
  src: url('../../fonts/V-Inter-Medium-500.woff2') format('woff2'),
    url('../../fonts/V-Inter-Medium-500.woff') format('woff');
  font-weight: 500;
}
