.thread-sidebar__container {
  display: flex;
  border-left: $box-line;
  flex-direction: column;
  flex: 2;
}

.thread-sidebar__container--reworked {
  position: absolute;
  border: 0;
  width: 100%;
  height: 100%;
  background: palette(greyscale-level-7);
  z-index: 1;
}
