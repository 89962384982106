$round-thumbnail-title-width: unit(27);
$big-thumbnail-title-width-mobile: unit(48);
$items-card-header-height: unit(12);
$thumbnail-title-wrapper-height: unit(11);
$item-box-width: unit(32.5);
$card-width: unit(74.5);
$banner-container-height: 312px;
$banner-mobile-container-height: 200px;
$foreground-wide-desktop-width: 764px;
$foreground-wide-tablet-width: 320px;
$foreground-wide-mobile-width: 158px;
$foreground-narrow-desktop-width: 280px;
$foreground-narrow-tablet-width: 256px;
$foreground-narrow-mobile-width: 146px;
$banner-description-height: 128px;
$banner-mobile-description-height: 88px;
$banner-title-height: 44px;
$banner-mobile-title-height: 36px;
