.item-sticky-buttons:not(:empty) {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw !important;
  z-index: map-get($zindex, large);
  display: flex;
  gap: unit(2);
  background-color: palette(greyscale-level-7);
  padding: unit(4);
  box-sizing: border-box;
  border-top: 1px solid $divider-color;
}
